import React, { useState } from "react"
import Autor from "./Autor"
import { useStore } from "../store"
import useAutors from "../hooks/useAutors"

const AllAutors = () => {
  const books = useAutors()
  const [active, setActive] = useState(1)
  const { searchName, sortDesc, tag } = useStore(state => state)
  const [pagination, setPagination] = useState(0)

  const renderBooks = books => {
    const _books = books
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .filter(book =>
        searchName.length > 0
          ? book.title.toLowerCase().includes(searchName.toLowerCase())
          : book
      )
    let pag = active - 1

    return {
      leng: _books.length,
      result: _books.slice(pag * 8, pag * 8 + 8).map(book => {
        return (
          <div
            key={book.title}
            className="w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 px-2"
          >
            <Autor
              {...book}
              cataleg={true}
              className={`w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 px-2 cursor-pointer`}
            />
          </div>
        )
      }),
    }
  }

  return (
    <React.Fragment>
      <div className="flex flex-wrap -mx-2 mt-12 -my-4">
        {renderBooks(books).result.length === 0 ? (
          <p className="text-green-600 text-lg mt-2 font-title h-screen text-center">
            No s'ha trobat cap coincidència...
          </p>
        ) : (
          renderBooks(books).result
        )}
      </div>

      <ul class="flex list-reset rounded w-auto font-title mt-8 cursor-pointer">
        {active > 1 && (
          <li onClick={() => setActive(active - 1)}>
            <p class="block hover:bg-malesherbes text-black border border-malesherbes h-8 w-8 rounded-md px-2 py-1 ml-1">
              &#x2190;
            </p>
          </li>
        )}

        <li>
          <p
            class="bg-malesherbes ml-1 text-white border-malesherbes h-8 w-auto p-1 text-center 
            rounded-md font-medium"
          >
            {active} de {Math.ceil(renderBooks(books).leng / 8)}
          </p>
        </li>

        {active < Math.ceil(renderBooks(books).leng / 8) && (
          <li onClick={e => setActive(active + 1)}>
            <p class="block hover:bg-malesherbes text-black border border-malesherbes h-8 w-8 rounded-md px-2 py-1 ml-1">
              &rarr;
            </p>
          </li>
        )}
      </ul>
    </React.Fragment>
  )
}

export default AllAutors
