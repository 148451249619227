import React from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import slugify from "slugify"
import Image from "gatsby-image"

const Autor = ({ title, imatge }) => {
  const styleProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <animated.div style={styleProps}>
      <Link to={`/autors/${slugify(title, { lower: true })}`}>
        <Image
          fluid={imatge.sharp.fluid}
          className="hover:shadow-2xl cursor-pointer "
          alt={`Autor ${title} de la Editorial Males Herbes`}
        />
      </Link>

      <div className="py-2">
        <div className="font-title text-myblack text-base">
          <Link to={`/autors/${slugify(title, { lower: true })}`}>{title}</Link>
        </div>
      </div>
    </animated.div>
  )
}

export default Autor
