import React from "react"
import { Link } from "gatsby"
import { useStore } from "../store"
import slugify from "slugify"
import Image from "gatsby-image"
import useAutors from "../hooks/useAutors"

const AllAutorsList = ({ initial }) => {
  const books = useAutors()
  const { searchName } = useStore(state => state)

  const renderBooks = books => {
    if (!books) {
      return
    }
    const _books = books
      .sort()
      .filter(book =>
        searchName.length > 0
          ? book.title.toLowerCase().includes(searchName.toLowerCase()) ||
            book.llibres
              .map(e => e.toLowerCase())
              .includes(searchName.toLowerCase())
          : book
      )

    // add overlfow

    return {
      leng: _books.length,
      result: _books.map(book => {
        return (
          <>
            <div class="flex mb-2 border-myblack border-b overflow-hidden">
              <div class="w-auto h-auto">
                <Link
                  to={`/autors/${slugify(book.title, { lower: true })}`}
                  className="cursor-pointer"
                >
                  <Image
                    fluid={book.imatge.small.fluid}
                    className="h-16 w-12"
                  />
                </Link>
              </div>
              <div class="w-full  h-auto">
                <p className="ml-4">
                  <Link
                    to={`/autors/${slugify(book.title, { lower: true })}`}
                    className="cursor-pointer"
                  >
                    <span className="text-md hover:underline  font-title italic font-bold truncate overflow-hidden">
                      {book.title}
                    </span>
                  </Link>
                </p>

                <p className="ml-4 my-2 truncate">
                  <span className="text-md font-title">
                    {book.bio.substring(0, 100)}...
                  </span>
                </p>
              </div>
            </div>
          </>
        )
      }),
    }
  }

  return <>{renderBooks(books).result}</>
}

export default AllAutorsList
