import { graphql, useStaticQuery } from "gatsby"

const useAutors = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            fields {
              sourceName
            }
            frontmatter {
              title
              bio
              llibres
              imatge {
                sharp: childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 960, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                small: childImageSharp {
                  id
                  fluid(maxWidth: 128, maxHeight: 128, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.allMarkdownRemark.edges
    .filter(({ node }) => node.fields.sourceName === "autors")
    .map(({ node }) => ({
      title: node.frontmatter.title,
      bio: node.frontmatter.bio,
      llibres: node.frontmatter.llibres,
      imatge: node.frontmatter.imatge,
    }))
}

export default useAutors
